import React, { useState } from "react";
import {
  Container,
  Offcanvas,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";

import "./Header.scss";

import { Logo } from "../logo";

const Header = () => {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);

  const handleNavClick = (path) => {
    navigate(path);
    setShowNav(false);
  };

  return (
    <Navbar
      className="fixed-top py-2 navbar-dark bg-dark"
      bg="primary"
      expand="md"
      variant="dark"
    >
      <Container className="lg" expand="md">
        <Logo />
        <LinkContainer to="/">
          <Navbar.Brand className="me-auto brand-text">
            DZP Games
          </Navbar.Brand> 
        </LinkContainer>
        {/**  
        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-md"
          onClick={() => setShowNav(true)}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-md"
          placement="end"
          show={showNav}
          onHide={() => setShowNav(false)}
        >
          <Offcanvas.Header
            closeButton
            closeVariant="white"
            className="darkPurple"
          >
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
              Delta Zero
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 py-1">
              <NavItem
                className="d-flex align-items-center"
                onClick={() => handleNavClick("/champions/details")}
              >
                Champions
              </NavItem>
              <NavItem
                className="d-flex align-items-center"
                onClick={() => handleNavClick("/champions/standings")}
              >
                Standings
              </NavItem>
              <span style={{ margin: "5px" }}></span>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>*/}
      </Container>
    </Navbar>
  );
};

export default Header;
