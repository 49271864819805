import style from "./Card.module.scss";

const Card = (props) => {
  let headerSection = (
    <div className="card-title">
      <h2>{props.header}</h2>
    </div>
  );

  return (
    <div className={`card ${style.card} ${props.className}`}>
      {props.header && headerSection}
      <div className="card-body">
        {props.title && <h4 className="card-title">{props.title}</h4>}
        {props.subHeader && (
          <h4 className={style.cardTextSh}>{props.subHeader}</h4>
        )}
        {props.children}
      </div>
    </div>
  );
};

export default Card;
