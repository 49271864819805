import { Fragment, useCallback, useEffect, useState } from "react";

import style from "./Standings.module.scss";
import gStyle from "../../SCSS/global.module.scss";
import Card from "../UI/Card";

const constructorURL =
  "https://dzp-standings-default-rtdb.firebaseio.com/constructors.json";
let constructorStandings = "N/A";

const ConstructorStandings = () => {
  const [constructors, setConstructors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchConstructor = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      let res = await fetch(constructorURL);

      if (!res.ok) {
        throw new Error("Something went wrong");
      }

      let data = await res.json();
      let constructorList = [];

      for (let key in data) {
        constructorList.push({
          id: key,
          name: data[key].name,
          flag: data[key].flag,
          points: data[key].points,
          team: data[key].team,
        });
      }

      setConstructors(constructorList);
    } catch (err) {
      setError(err.message);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchConstructor();
  }, [fetchConstructor]);

  if (constructors.length > 0) {
    constructorStandings = constructors;

    constructorStandings.sort((a, b) => {
      return b.points - a.points;
    });
  }

  return error ? (
    <p>error</p>
  ) : isLoading ? (
    <p>Loading...</p>
  ) : constructorStandings === "N/A" ? (
    <p>No Standings Found</p>
  ) : (
    <Fragment>
      {constructorStandings.map((constructor, i) => (
        <Card
          key={constructor.id}
          className={`${style.driver} ${style.greyBG}`}
        >
          <div
            className={`${gStyle.flex} ${gStyle.flexSpaceBetween} ps-3 pe-3`}
          >
            <div className={`${gStyle.flex} ${gStyle.flexAlignItemsCenter}`}>
              <p className={`${style.dText} ${gStyle.rt} ${style.rank}`}>
                {i + 1}
              </p>
              <div
                className={`${style.teamColor} ${getTeamColor(
                  constructor.name
                )} ms-2 me-2`}
              ></div>
              <p className={`${style.dText} ${gStyle.lt} me-2`}>
                {constructor.name}
              </p>
            </div>
            <div className={`${gStyle.flex} ${gStyle.flexAlignItemsCenter}`}>
              <p
                className={`${style.dText} ${gStyle.lt} ${gStyle.upp} ${gStyle.smText}`}
              >
                {constructor.points} pts
              </p>
            </div>
          </div>
        </Card>
      ))}
    </Fragment>
  );
};

const getTeamColor = (team) => {
  switch (team) {
    case "Ferrari":
      return style.ferrari;
    case "Mercedes":
      return style.mercedes;
    case "Haas":
      return style.haas;
    case "McLaren":
      return style.mclaren;
    case "Aston Martin":
      return style.aston;
    case "Alfa Romeo":
      return style.alfa;
    case "Red Bull":
      return style.redbull;
    case "Alpine":
      return style.alpine;
    case "AlphaTauri":
      return style.alphatauri;
    case "Williams":
      return style.williams;
    case "Reserve":
      return style.dzp;
    default:
      return style.dzp;
  }
};

export default ConstructorStandings;
