import { Fragment, useCallback, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";

import style from "./Standings.module.scss";
import gStyle from "../../SCSS/global.module.scss";
import Card from "../UI/Card";
// import { DRIVER_STANDINGS } from "./Standings-Store";
import WorldFlag from "../../Images/WorldFlag.svg";

const driverURL = 'https://dzp-standings-default-rtdb.firebaseio.com/drivers.json';
let driverStandings = 'N/A';
let topThree;

const Standings = (props) => 
{
    const [drivers, setDrivers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchDrivers = useCallback(async () =>
    {
        setIsLoading(true);
        setError(null);

        try
        {
            let res = await fetch(driverURL);

            if(!res.ok)
            {
                throw new Error('Something went wrong');
            }

            let data = await res.json();
            let driverList = [];

            for(let key in data)
            {
                driverList.push({
                    id: key,
                    name: data[key].name,
                    flag: data[key].flag,
                    points: data[key].points,
                    team: data[key].team
                })
            }

            setDrivers(driverList);
        }
        catch(err)
        {
            setError(err.message);
        }

        setIsLoading(false);
    }, []);

    useEffect(() =>
    {
        fetchDrivers();
    }, [fetchDrivers]);

    if(drivers.length > 0)
    {
        driverStandings = drivers;
        driverStandings.sort((a, b) => {
            return b.points - a.points;
        });

        if(props.view === 'summary')
        {
            driverStandings = driverStandings.slice(0,20)
            topThree = sortTopThree(driverStandings);
        }
    }

    return (
        error ? <p>error</p> : isLoading ? <p>Loading...</p> : driverStandings === 'N/A' ? <p>No Standings Found</p> :
        <Fragment>
            <div className={`${gStyle.flex} ${gStyle.flexCenter1} ${style.topThreeDrivers} mb-4`}>
            {props.view !== 'summary' ? '' : topThree.map((driver, i) => (
                <Col key={driver.id} className={i === 0 ? style.p2 : i === 1 ? `ms-3 me-3 ${style.p1}` : style.p3}>
                    <Card
                        key={driver.id}
                        className={`${style.driver} ${style.blackBG} ${style.topThree} p-3`}
                    >
                        <div className={` ${gStyle.flex} ${gStyle.flexAlignItemsCenter}`}>
                            <div className="me-4">
                                {i === 0 ? <h2>II</h2> : i === 1 ? <h2>I</h2> : <h2>III</h2>}
                            </div>
                            <div>
                                <p className={`${style.dText} ${gStyle.lt} ${gStyle.mdText} me-2`}>
                                    {driver.name}
                                </p>
                                <p className={`${style.dText} ${gStyle.smText} ${gStyle.lt}`}>
                                    {driver.team}
                                </p>
                            </div>
                            <div>
                                {driver.flag !== "WF" ? (
                                <ReactCountryFlag
                                    className={`${style.topFlag} ms-4`}
                                    countryCode={driver.flag}
                                    svg
                                />
                                ) : (
                                <img
                                    src={WorldFlag}
                                    className={`${style.topFlag} ms-4`}
                                    alt={`${driver.flag} flag`}
                                    title={driver.flag}
                                />
                                )}
                            </div>
                        </div>
                    </Card>
                </Col>
            ))}
            </div>
            {driverStandings.map((driver, i) => (
                <Card key={driver.id} className={`${style.driver} ${style.blackBG}`}>
                    <div
                        className={`${gStyle.flex} ${gStyle.flexSpaceBetween} ps-3 pe-3`}
                    >
                        <div className={`${gStyle.flex} ${gStyle.flexAlignItemsCenter}`}>
                            <p className={`${style.dText} ${gStyle.rt} ${style.rank}`}>
                                {i + 1}
                            </p>
                            <div
                                className={`${style.teamColor} ${getTeamColor(driver.team)} ms-2 me-2`}
                            ></div>
                            {driver.flag !== "WF" ? (
                            <ReactCountryFlag
                                className={`${style.flag} me-2`}
                                countryCode={driver.flag}
                                svg
                            />
                            ) : (
                            <img
                                src={WorldFlag}
                                className={`${style.flag} me-2`}
                                alt={`${driver.flag} flag`}
                                title={driver.flag}
                            />
                            )}
                            {/* {console.log(driver.flag)} */}
                            <p className={`${style.dText} ${gStyle.lt} me-2`}>
                                {driver.name}
                            </p>
                            <p className={`${style.dText} ${gStyle.smText} ${gStyle.lt}`}>
                                {driver.team}
                            </p>
                        </div>
                        <div className={`${gStyle.flex} ${gStyle.flexAlignItemsCenter}`}>
                            <p
                                className={`${style.dText} ${gStyle.lt} ${gStyle.upp} ${gStyle.smText}`}
                            >
                                {driver.points} pts
                            </p>
                        </div>
                    </div>
                </Card>
            ))}
        </Fragment>
    );
};

const sortTopThree = (drivers) => {
  let top = drivers.slice(0, 3);
  let temp;

  temp = top[1];
  top[1] = top[0];
  top[0] = temp;

  return top;
};

const getTeamColor = (team) => {
  switch (team) {
    case "Ferrari":
      return style.ferrari;
    case "Mercedes":
      return style.mercedes;
    case "Haas":
      return style.haas;
    case "McLaren":
      return style.mclaren;
    case "Aston Martin":
      return style.aston;
    case "Alfa Romeo":
      return style.alfa;
    case "Red Bull":
      return style.redbull;
    case "Alpine":
      return style.alpine;
    case "AlphaTauri":
      return style.alphatauri;
    case "Williams":
      return style.williams;
    case "Reserve":
      return style.dzp;
    default:
      return style.dzp;
  }
};

export default Standings;
