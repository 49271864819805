import React, { Fragment } from "react";
import { Container, Col, Row } from "react-bootstrap";

import style from "./Details.module.scss";
import gStyle from "../../SCSS/global.module.scss";
import Standings from "../../Components/Standings/Standings";
import ConstructorStandings from "../../Components/Standings/Constructors-Standing";

const StandingsScreen = () => {
    return (
        <Fragment>
            <section className={style.hero}>
                <h1 className={`${gStyle.wh} ${gStyle.whTs} ${gStyle.ct}`}>
                    Champions' Series Standings
                </h1>
            </section>
            <section className={`s2 ${gStyle.darkGrayBG}`}>
                <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
                    <Row>
                        <Col>
                            <h2 className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} mb-4`}>
                                Driver Standings
                            </h2>
                            <Standings />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={`s3 ${gStyle.blackBG}`}>
                <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
                    <Row>
                        <Col>
                            <h2 className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} mb-4`}>
                                Constructors Standings
                            </h2>
                            <ConstructorStandings />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};

export default StandingsScreen;
