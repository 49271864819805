import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import style from "./Footer.module.scss";
import gStyle from "../../SCSS/global.module.scss";

import DZPwebLogo from "../../Images/WhiteWhite.svg";
import DiscordLogo from "../../Images/discord-white.svg";
import TwitchLogo from "../../Images/twitch-white.svg";
import YouTubeLogo from "../../Images/youtube-white.svg";
import TwitterLogo from "../../Images/twitter-white.svg";
import SimCoachesLogo from "../../Images/SimCoachesLogo.svg";
import XinxLogo from "../../Images/Xwhite.png";

const Footer = () => {
  return (
    <footer className={gStyle.cyanBG}>
      <Container>
        <Row
          className={`${style.contain} ${gStyle.flex} ${gStyle.flexAlignItemsTop}`}
        >
          <Col md={5} className={style.mainLogoContainer}>
            <Col
              className={`${gStyle.flex} ${gStyle.flexStart} ${gStyle.flexAlignItemsCenter}`}
            >
              <img
                src={DZPwebLogo}
                className={style.dzp}
                alt="Delta Zero Production"
              />
              {/* <h2 className={`${gStyle.wh} ${gStyle.whTs} ms-3`}>
                        Delta Zero Production
                    </h2> */}
            </Col>
          </Col>
          <Col
            xs="auto"
            md={7}
            className={`${style.right} ${gStyle.flex} ${gStyle.flexSpaceBetween} ${gStyle.flexAlignItemsTop}`}
          >
            <Col md={7}>
              <Row>
                <h4 className={`${gStyle.wh} ${gStyle.whTs} ${gStyle.ct}`}>
                  Follow Us
                </h4>
              </Row>
              <Row
                className={`${gStyle.flex} ${gStyle.flexSpaceBetween} ${gStyle.flexAlignItemsCenter}`}
              >
                <Col className={`${style.logo} ${gStyle.ct} py-3`}>
                  <a
                    href="https://discord.gg/dKxK6tnz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={DiscordLogo} alt="Discord" />
                  </a>
                </Col>
                <Col className={`${style.logo} ${gStyle.ct} py-3`}>
                  <a
                    href="https://www.twitch.tv/dzpgames"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TwitchLogo} alt="Twitch" />
                  </a>
                </Col>
                <Col className={`${style.logo} ${gStyle.ct} py-3`}>
                  <a
                    href="https://www.youtube.com/@deltazeroproduction"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={YouTubeLogo} alt="YouTube" />
                  </a>
                </Col>
                {/**<Col className={`${style.logo} ${gStyle.ct} py-3`}>
                  <a
                    href="https://twitter.com/DeltaZeroProd"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TwitterLogo} alt="Twitter" />
                  </a>
                </Col>**/}
              </Row>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={`${style.copy} ${gStyle.wh} ${gStyle.whTs}`}>
              &copy; 2024 Delta Zero, LLC. All Rights Reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
