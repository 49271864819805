import React, { Fragment } from "react";
import { Container, Col, Row } from "react-bootstrap";
import style from "./Home.module.scss";
import gStyle from "../../SCSS/global.module.scss";
//import Podium from "../../Images/podium_logos/cat_head_BW.svg";

const LandingPage = () => {
  return (
    <Fragment>
      <section className={style.hero}>
        <h1 className={`${gStyle.wh} ${gStyle.whTs} ${gStyle.ct} ${gStyle.shadow}`}>
        Building Worlds Without Boundaries
        </h1>
      </section>
      {/**<section className={`${style.s3} ${gStyle.trackImageBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row className={`${style.col3} ${gStyle.ct}`}>
            <Col>
              <img
                src={Community}
                className={`${style.image} ${gStyle.cyanFilter} d-block mx-auto img-fluid w-50`}
                alt="Community"
              />
              <h4 className={`${gStyle.wh} ${gStyle.whTs}`}>
                Find a Community and start racing today
              </h4>
            </Col>
            <Col>
              <img
                src={F123}
                className={`${style.image} ${gStyle.cyanFilter} d-block mx-auto img-fluid w-60`}
                alt="F1 23"
              />
              <h4 className={`${gStyle.wh} ${gStyle.whTs}`}>
                Built for F1 23 League Managers and Drivers
              </h4>
            </Col>
            <Col>
              <img
                src={Computer}
                className={`${style.image} ${gStyle.cyanFilter} d-block mx-auto img-fluid w-50`}
                alt="Tools"
              />
              <h4 className={`${gStyle.wh} ${gStyle.whTs}`}>
                Advanced Broadcasting and Telemetry Tools
              </h4>
            </Col>
          </Row>

        </Container>
      </section>*/}
      <section className={`s2 ${gStyle.darkGrayBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} mb-4`}
              >
                Who We Are
              </h2>
              <p className={`${gStyle.mdText} ${gStyle.wh}`}>
              As DZP Games, the game development studio of Delta Zero Production, we are a small, passionate team of creators building worlds that unite players. Games have the power to transcend barriers and bring people together, and this drives everything we do. Using innovative game design, we create inclusive spaces where shared experiences spark adventure, belonging, and discovery.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={`${style.s5} ${gStyle.trackImageBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.magenta} ${gStyle.magentaTs} mb-4`}
              >
                Core Values
              </h2>
            </Col>
          </Row>
          <Row className={style.row2}>
            <Col>
              <h3 className={`${gStyle.mdText} ${gStyle.wh} ${gStyle.whTs} `}>
              Player-Driven Community
              </h3>
              <p className={`${gStyle.wh} ${gStyle.noTs} ${gStyle.shadow}`}>
              We believe in the power of community to shape better experiences. Our players' voices guide our development, and we grow through meaningful connections with our community. Every decision we make starts with considering our players' needs and experiences.
              </p>
            </Col>
            <Col>
              <h3 className={`${gStyle.mdText} ${gStyle.wh} ${gStyle.whTs}`}>
              Creative Innovation
              </h3>
              <p className={`${gStyle.wh} ${gStyle.noTs} ${gStyle.shadow}`}>
              We pursue creative solutions that bring joy to our players. By combining innovative design with intuitive systems, we craft engaging experiences that feel natural and fun. Our curiosity drives us to experiment and find magic in unexpected places.
              </p>
            </Col>
            <Col>
              <h3 className={`${gStyle.mdText} ${gStyle.wh} ${gStyle.whTs}`}>
              Respect & Integrity
              </h3>
              <p className={`${gStyle.wh} ${gStyle.noTs} ${gStyle.shadow}`}>
              We create safe, accessible spaces where all players feel welcome. We're committed to transparent communication and honest engagement with our community. We actively champion inclusivity and take responsibility for fostering a respectful gaming environment.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={`s4 ${gStyle.darkGrayBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} mb-4`}
              >
                Mission Statement
              </h2>
              <p className={`${gStyle.mdText} ${gStyle.wh}`}>
              Our mission is to create digital worlds where games foster connection and creativity. We believe games have the unique power to unite across boundaries, sparking joy, curiosity, and wonder. By blending innovative design with a commitment to our player-driven community, we shape experiences where everyone can grow, connect, and express themselves through the magic of gaming.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/** Moved to champions/standings 
      <section className={`s6 ${gStyle.darkGrayBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} mb-4`}
              >
                Driver Standings
              </h2>
              <Standings />
            </Col>
          </Row>
        </Container>
      </section>
      */}
      {/* <Container className={`${gStyle.container} mt-3`}>
				<Row>
					<Col lg={7} md={9} xs={12} className={`${gStyle.mCenter} mt-5`}>
						<Card 
							className={`${gStyle.wh} ${gStyle.darkPurpleBG} mt-2 mb-3`}
							header='Champions Series'
							title='a F1 22 League by'
							subHeader='DELTA ZERO PRODUCTION'
						>
							<p className="card-text">
								Recruiting Drivers, Race Engineers, Race Stewards, Race
								Directors, Race Commentators, Content Creators, Moderators,
								Graphic Designers, 3D Artists, Web Developers, App Developers,
								and more
							</p>
							<Button
								variant="dark"
								type="button"
								href="https://forms.gle/5K5Cf9cxHvrdDgMe9"
								target="_blank"
								rel="noreferrer"
							>
								Click HERE to fill out our Interest Form
							</Button>
						</Card>
					</Col>
				</Row>
				<Row xs="auto" className="justify-content-center align-self-center mt-3">
					<Col lg={6} md={6} xs={12} className="mb-3">
						<Card
							className={`${gStyle.borderInfo} xs`}
							title= 'Commentary'
						>
							<p className="card-text">All races streamed on Twitch by a dedicated commentary team</p>
							<p className="card-text">Custom interactive graphics using telemetry data</p>
						</Card>
					</Col>
					<Col lg={6} md={6} xs={12} className="mb-3">
						<Card
							className={`${gStyle.borderWarning} xs`}
							title='Community'
						>
							<p className="card-text">Creating fun and engaging content</p>
							<p className="card-text">
								Providing opportunities fora diverse community to connect with
								one another
							</p>
						</Card>
					</Col>
				</Row>
			</Container> */}
    </Fragment>
  );
};

export default LandingPage;
