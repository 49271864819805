import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Standings from "../../Components/Standings/Standings";

import AbuDhabi from "../../Images/track_list/abu_dhabi_ai_trackspace.png";
//import Austria from "../../Images/track_list/austria_ai_trackspace.png";
// import Bahrain from '../../Images/track_list/bahrain_ai_trackspace.png';
//import Baku from "../../Images/track_list/baku_ai_trackspace.png";
import Brazil from "../../Images/track_list/brazil_ai_trackspace.png";
// import Spain from '../../Images/track_list/catalunya_ai_trackspace.png';
import Saudi from "../../Images/track_list/jeddah_ai_trackspace.png";
//import Vegas from "../../Images/track_list/las_vegas_ai_trackspace.png";
//import Qatar from "../../Images/track_list/losail_ai_trackspace.png";
//import Monaco from "../../Images/track_list/monaco_ai_trackspace.tga.png";
import Monza from "../../Images/track_list/mza_ai_trackspace.png";
import Portugal from "../../Images/track_list/portimao_ai_trackspace.png";
import GBR from "../../Images/track_list/silverstone_ai_trackspace.png";
import Singapore from "../../Images/track_list/singapore_ai_trackspace.png";
import Spa from "../../Images/track_list/spa_francorchamps_ai_trackspace.tga.png";
import Japan from "../../Images/track_list/suzuka_ai_trackspace.png";
import USA from "../../Images/track_list/tex_ai_trackspace.png";
import China from "../../Images/track_list/shanghai_ai_trackspace.png";
import Hungary from "../../Images/track_list/hungaroring_ai_trackspace.png";

import style from "./Details.module.scss";
import gStyle from "../../SCSS/global.module.scss";
import { Link } from "react-router-dom";

// This is really gross... please fix me. We just want basic details about what the Champions Series is here. It can have the latest YT video (use Components/YouTube/YouTubeEmbed) and even our Drivers Handbook and Series Rulebook
import YoutubeEmbed from "../../Components/YouTube/YouTubeEmbed";

const calendarURL =
  "https://dzp-standings-default-rtdb.firebaseio.com/calendar/s3.json";
let calendarList;

const DetailsScreen = () => {
  const [calendar, setCalendar] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [activeSlide, setActiveSlide] = useState({
    activeIndex: 0,
    currDate: `${new Date().getMonth()}${("0" + new Date().getDate()).slice(
      -2
    )}`,
  });

  const fetchCalendar = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      let res = await fetch(calendarURL);

      if (!res.ok) {
        throw new Error("Something went wrong");
      }

      let data = await res.json();
      let calendarList = [];

      for (let key in data) {
        calendarList.push({
          id: key,
          index: data[key].index,
          name: data[key].name,
          location: data[key].location,
          date: data[key].date,
        });
      }

      setCalendar(calendarList);
    } catch (err) {
      setError(err.message);
    }

    setIsLoading(false);
  }, []);

  function trackClicked(companyNum) {
    if (activeSlide.activeIndex === companyNum) {
      return setActiveSlide({
        ...activeSlide,
      });
    }

    setActiveSlide({
      ...activeSlide,
      activeIndex: companyNum,
    });
  }

  // console.log(activeSlide.currDate)

  const upcomingTrack = () => {
    calendarList = calendar;
    calendarList.sort((a, b) => {
      return a.index - b.index;
    });

    // console.log(`${+calendarList[0].date} > ${+activeSlide.currDate}`)
    //console.log(+calendarList[0].date > +activeSlide.currDate)
    //console.log(calendar + ' test');

    for (let i = 0; i < calendarList.length; i++) {
      //console.log(calendarList[i].date);

      if (+calendarList[i].date >= +activeSlide.currDate) {
        setActiveSlide({
          ...activeSlide,
          activeIndex: calendarList[i].index,
        });
        break;
      }
    }
  };

  useEffect(() => {
    fetchCalendar();

    if (calendar.length > 0) {
      upcomingTrack();
    }
  }, [fetchCalendar, calendar.length]);

  return (
    <Fragment>
      <div className={style.spacer}></div>
      <YoutubeEmbed embedId="R3eDR91BtkE" />
      <section className={style.hero}>
        <h1 className={`${gStyle.wh} ${gStyle.whTs} ${gStyle.ct}`}>
          Champions Series
        </h1>
      </section>
      <section className={`${style.s2} ${gStyle.trackImageBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row className={`${style.col3} ${gStyle.ct}`}>
            <Col>
              <h3 className={`${gStyle.wh} ${gStyle.whTs}`}>Seasons</h3>
              <p className={`${gStyle.xxlText} ${gStyle.wh}`}>4</p>
            </Col>
            <Col className={style.lrBorder}>
              <h3 className={`${gStyle.wh} ${gStyle.whTs}`}>Drivers</h3>
              <p className={`${gStyle.xxlText} ${gStyle.wh}`}>20+</p>
            </Col>
            <Col>
              <h3 className={`${gStyle.wh} ${gStyle.whTs}`}>Teams</h3>
              <p className={`${gStyle.xxlText} ${gStyle.wh}`}>10</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={`s3 ${gStyle.darkGrayBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} mb-4`}
              >
                About
              </h2>
              <p className={`${gStyle.mdText} ${gStyle.wh}`}>
                The division is used to provide opportunities for a diverse
                community to connect with one another, and to create fun and
                engaging content.
              </p>
              <p className={`${gStyle.mdText} ${gStyle.wh}`}>
                All the races are streamed on YouTube by a dedicated commentary
                team, and custom interactive graphics using telemetry data.
              </p>
              <p className={`${gStyle.mdText} ${gStyle.wh}`}>
                Currently, we are recruiting drivers, race engineers, race
                stewards, race directors, race commentators, content creators,
                moderators, graphic designers, 3D artists, web developers, app
                developers and more. If you're interested, please sign up by
                clicking on the interest form button below.
              </p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdxM9NFs9Psk04-1s0MtOmq_hFvFoIIkOh7OlyRae-JNl6XiQ/viewform?usp=sf_link"
                target="_blank"
                rel="noreferrer"
                className={`${style.button} ${gStyle.mdText} ${gStyle.wh}`}
              >
                Interest Form
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={`s4 ${gStyle.blackBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} mb-4`}
              >
                Race Schedule
              </h2>
            </Col>
          </Row>
          <Row>
            <div className={`${gStyle.container} ${style.slider}`}>
              <div className={style.leftTh}>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 1 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(1)}
                >
                  British GP
                </div>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 2 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(2)}
                >
                  Hungarian GP
                </div>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 3 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(3)}
                >
                  Belgian GP
                </div>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 4 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(4)}
                >
                  Italian Sprint GP
                </div>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 5 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(5)}
                >
                  Portuguese GP
                </div>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 6 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(6)}
                >
                  Abu Dhabi GP
                </div>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 7 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(7)}
                >
                  Saudi Arabian GP
                </div>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 8 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(8)}
                >
                  Japanese Sprint GP
                </div>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 9 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(9)}
                >
                  Singapore GP
                </div>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 10 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(10)}
                >
                  Chinese GP
                </div>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 11 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(11)}
                >
                  United States GP
                </div>
                <div
                  className={`${style.track} ${
                    activeSlide.activeIndex === 12 ? style.active : ""
                  }`}
                  onClick={() => trackClicked(12)}
                >
                  São Paolo Sprint GP
                </div>
              </div>
              <div className={style.right}>
                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 1 ? style.active : ""
                  }`}
                  key={1}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 01 - British Grand Prix
                  </h3>
                  <p className={gStyle.smText}>February 10</p>
                  <img src={GBR} alt="British GP Track Map" />
                </div>

                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 2 ? style.active : ""
                  }`}
                  key={2}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 02 - Hungarian Grand Prix
                  </h3>
                  <p className={gStyle.smText}>February 17</p>
                  <img src={Hungary} alt="Hungarian GP Track Map" />
                </div>

                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 3 ? style.active : ""
                  }`}
                  key={3}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 03 - Belgian Grand Prix
                  </h3>
                  <p className={gStyle.smText}>February 24</p>
                  <img src={Spa} alt="Belgian GP Track Map" />
                </div>

                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 4 ? style.active : ""
                  }`}
                  key={4}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 04 - Italian Sprint GP
                  </h3>
                  <p className={gStyle.smText}>March 2</p>
                  <img src={Monza} alt="Italian GP Track Map" />
                </div>

                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 5 ? style.active : ""
                  }`}
                  key={5}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 05 - Portuguese Grand Prix
                  </h3>
                  <p className={gStyle.smText}>March 16</p>
                  <img src={Portugal} alt="Portuguese GP Track Map" />
                </div>

                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 6 ? style.active : ""
                  }`}
                  key={6}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 06 - Abu Dhabi Grand Prix
                  </h3>
                  <p className={gStyle.smText}>March 23</p>
                  <img src={AbuDhabi} alt="Abu Dhabi GP Track Map" />
                </div>

                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 7 ? style.active : ""
                  }`}
                  key={7}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 07 - Saudi Arabian Grand Prix
                  </h3>
                  <p className={gStyle.smText}>March 30</p>
                  <img src={Saudi} alt="Saudi Arabian GP Track Map" />
                </div>

                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 8 ? style.active : ""
                  }`}
                  key={8}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 08 - Japanese Sprint GP
                  </h3>
                  <p className={gStyle.smText}>April 6</p>
                  <img src={Japan} alt="Japanese GP Track Map" />
                </div>

                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 9 ? style.active : ""
                  }`}
                  key={9}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 09 - Singapore Grand Prix
                  </h3>
                  <p className={gStyle.smText}>April 13</p>
                  <img src={Singapore} alt="Singapore GP Track Map" />
                </div>

                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 10 ? style.active : ""
                  }`}
                  key={10}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 10 - Chinese Grand Prix
                  </h3>
                  <p className={gStyle.smText}>April 20</p>
                  <img src={China} alt="Chinese GP Track Map" />
                </div>

                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 11 ? style.active : ""
                  }`}
                  key={11}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 11 - United States Grand Prix
                  </h3>
                  <p className={gStyle.smText}>April 27</p>
                  <img src={USA} alt="United States GP Track Map" />
                </div>

                <div
                  className={`${style.slide} ${
                    activeSlide.activeIndex === 12 ? style.active : ""
                  }`}
                  key={12}
                >
                  <h3 className={`${gStyle.smdText} ${gStyle.med}`}>
                    Round 12 - São Paulo Sprint GP
                  </h3>
                  <p className={gStyle.smText}>May 11</p>
                  <img src={Brazil} alt="São Paulo GP Track Map" />
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className={`s5 ${gStyle.darkGrayBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} ${style.dTitle} mb-4`}
              >
                Driver Standings
                <span>As of the British Grand Prix</span>
              </h2>
              <Standings view="summary" />
              <Link
                to={"/champions/standings"}
                className={`${style.button} ${gStyle.smdText} ${gStyle.wh}`}
              >
                View Full Standings
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className={`s4 ${gStyle.darkGrayBG}`}>
                <Container className={`${gStyle.container} mt-3`}>
                    <Row>
                        <Col lg={7} md={9} xs={12} className={`${gStyle.mCenter} mt-5`}>
                            <Card
                            className={`${gStyle.wh} ${gStyle.darkPurpleBG} mt-2 mb-3`}
                            header="Champions Series"
                            title="a F1 22 League by"
                            subHeader="DELTA ZERO PRODUCTION"
                            >
                            <p className="card-text">
                                Recruiting Drivers, Race Engineers, Race Stewards, Race
                                Directors, Race Commentators, Content Creators, Moderators,
                                Graphic Designers, 3D Artists, Web Developers, App Developers,
                                and more
                            </p>
                            <Button
                                variant="dark"
                                type="button"
                                href="https://forms.gle/5K5Cf9cxHvrdDgMe9"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Click HERE to fill out our Interest Form
                            </Button>
                            </Card>
                        </Col>
                    </Row>
                    <Row
                    xs="auto"
                    className="justify-content-center align-self-center mt-3"
                    >
                        <Col lg={6} md={6} xs={12} className="mb-3">
                            <Card className={`${gStyle.borderInfo} xs`} title="Commentary">
                            <p className="card-text">
                                All races streamed on Twitch by a dedicated commentary team
                            </p>
                            <p className="card-text">
                                Custom interactive graphics using telemetry data
                            </p>
                            </Card>
                        </Col>
                        <Col lg={6} md={6} xs={12} className="mb-3">
                            <Card className={`${gStyle.borderWarning} xs`} title="Community">
                            <p className="card-text">Creating fun and engaging content</p>
                            <p className="card-text">
                                Providing opportunities fora diverse community to connect with
                                one another
                            </p>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section> */}
    </Fragment>
  );
};

export default DetailsScreen;
