import { Fragment } from "react";
//import style from "./App.module.scss";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Home from "./Screens/HomePage/Home";
import DetailsScreen from "./Screens/ChampionsScreens/DetailsScreen";
import StandingsScreen from "./Screens/ChampionsScreens/StandingsScreen";
import { Route, Routes, useLocation } from "react-router-dom";

import gStyle from "./SCSS/global.module.scss";

function App() {
  let { pathname } = useLocation();

  if (pathname === "/") {
    pathname = "/home";
  }

  pathname = pathname.split("/");

  return (
    <Fragment>
      <Header />
      <main
        id={pathname[1]}
        className={`App ${pathname[1] !== "home" ? gStyle.inner : ""}`}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/champions/details" element={<DetailsScreen />} />
          <Route path="/champions/standings" element={<StandingsScreen />} />
          {/* Catch-all route */}
          <Route path="*" element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </Fragment>
  );
}

export default App;
