import React from "react";
import styled from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import DeltaZeroLogoImg from "../../Images/BluePurple.svg";

const LogoImg = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-right: 10px;
  width: 40px;
  height: 28px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
`;

export function Logo(props) {
  return (
    <LinkContainer to="/">
      <LogoImg>
        <img src={DeltaZeroLogoImg} alt="Delta Zero Production" />
      </LogoImg>
    </LinkContainer>
  );
}
